<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />

    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" style="margin-top:60px !important;" />
      
        <v-row class="m-2" :style="`direction:`+lang.dir+`;margin:5px;margin-top:10px;margin-left:30px;margin-right:30px;`">
            <v-col cols="12" md="12" sm="12" :style="`direction:`+lang.ldir" class="text-end">
                <button id="multiply-button" :style="`direction:`+lang.dir" :class="`multiply-button-`+lang.lalgin" @click="addnew({id:0})" v-b-toggle.add_category>
                    <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add}}</div>
                </button>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="product_categories"
                    disable-pagination
                    hide-default-footer
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td style="width:35%;" class="text-center">{{ row.item.namear }}</td>
                            <td style="width:35%;" class="text-center" nowrap>{{ row.item.nameen }}</td>
                            <td style="width:10%;" class="text-center" nowrap>{{ row.item._type }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item._parent }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.subcats }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.products }}</td>
                            <td style="width:10%;background:blue; border:2px solid #fff !important;" class="text-center">
                                <b-button class="btn btn-sm btn-prrmary m-1" style="border:none;background:blue !important" @click="addnew(row.item)" v-b-toggle.add_category>{{lang.update}}</b-button>
                                <b-button class="btn btn-sm btn-danger m-1" v-if="row.item.products == 0 && row.item.subcats == 0" @click="delItem(row.item)">{{lang.delete}}</b-button>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <vue-snotify></vue-snotify>
        <products-addcats ref="AddCategory" />
    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import ProductsAddcats from '@/components/products-addcats.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ExportsPrint from '@/components/exports-print.vue'
export default{
  components: { breadCrumbs, TabsComp, ProductsAddcats, HeaderPortrate,Footer,ExportsPrint},
    name: 'Products',
    data() {
        return {
            active_tab:0,
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            product_categories: [],
        }
    },
    created() {
        this.getCats();
    },
    methods: {
        delItem(item) {
            const post = new FormData();
            post.append("type","DelCats");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',item.id);
            post.append('data[lang]',this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                this.getCats();
            });
        },
        addnew(item){
            if(item.id == 0){
                this.$refs.AddCategory.namear = '';
                this.$refs.AddCategory.nameen = '';
                this.$refs.AddCategory.parentid = 0;
                this.$refs.AddCategory.changeType(1);
                this.$refs.AddCategory.id = 0;
            }else{
                this.$refs.AddCategory.namear = item.namear;
                this.$refs.AddCategory.nameen = item.nameen;
                this.$refs.AddCategory.parentid = item.parentid == 0 ? 0 : item.parentid;
                this.$refs.AddCategory.changeType(item.type == 0 ? 0 : 1);
                this.$refs.AddCategory.id = item.id;
            }
        },
        getCats(){
            const post = new FormData();
            post.append("type","getPCats");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',"all");
            post.append('data[lang]',this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                const p = res.data;
                // console.log(p);
                let t = [];
                if(typeof p.results !== 'undefined' && typeof p.results.data !== 'undefined'){
                    if(p.results.data.length != 0){
                        for(let i=0;i<p.results.data.length;i++){
                            t.push(p.results.data[i]);
                        }
                    }
                }
                this.product_categories = t;
            });
        },
        preparedata(){

            let t = this.headers;
            let data = this.product_categories;
            let hdata = [];
            let exdata = [];
            for(let i=0;i<t.length;i++){
                hdata.push(t[i].text);
            }
            exdata.push(hdata);
            for(let k=0;k<data.length;k++){
            let x = [];
                x.push(data[k].namear);
                x.push(data[k].nameen);
                x.push(data[k]._type);
                x.push(data[k]._parent);
                x.push(data[k].subcats);
                x.push(data[k].products);
                exdata.push(x);
            }
            this.$refs.exportbar.exportdata = exdata;
            
            this.$refs.exportbar.exporttitle = this.lang.product_categories;
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            return [
                {
                    text: this.lang.category_name_ar,
                    align: 'center',
                    filterable: false,
                    value: 'namear',
                    class: 'backBlack'
                },
                {
                    text: this.lang.category_name_en,
                    align: 'center',
                    filterable: false,
                    value: 'nameen',
                    class: 'backBlack'
                },
                {
                    text: this.lang.category_type,
                    align: 'center',
                    filterable: false,
                    value: 'type',
                    class: 'backBlack'
                },
                {
                    text: this.lang.category_parent,
                    align: 'center',
                    filterable: false,
                    value: 'parentid',
                    class: 'backBlack'
                },
                {
                    text: this.lang.subcat_count,
                    align: 'center',
                    filterable: false,
                    value: 'subcat_count',
                    class: 'backBlack'
                },
                {
                    text: this.lang.products,
                    align: 'center',
                    filterable: false,
                    value: 'products',
                    class: 'backBlack'
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    filterable: false,
                    class: 'backBlack',
                    value: '',
                },
            ]
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.product_categories,
                    disabled: true,
                    to: '/inventory/products',
                }
        }
    },
}
</script>